<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">{{current_page}}</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5" v-if="alert_message!='PLEASE WAIT...'">
          <v-flex xs12 md12>
            <v-select
              v-model="month_of_id"
              class="mx-2"
              dense
              outlined
              label="Month Of"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              :rules="rules.combobox_rule"
              @change="selected_selection"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="selection"
              class="mx-2"
              dense
              outlined
              label="Data"
              :items="['Current','Non Current']"
              :rules="rules.combobox_rule"
              @change="selected_selection"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-if="category_id!=''&&!is_liquidation"
              v-model="view_by"
              class="mx-2"
              dense
              outlined
              label="View By"
              :items="['Pie Graph','Figures']"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="option"
              class="mx-2"
              dense
              outlined
              label="Option"
              :items="option_items"
              :rules="rules.combobox_rule"
              @change="selected_option"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12 v-if="option==='Particulars'">
            <v-select
              v-model="particulars_id"
              class="mx-2"
              dense
              outlined
              label="Particulars Data"
              :items="particulars_items"
              item-value="id"
              item-text="particulars"
              :rules="rules.combobox_rule"
              @change="view_report"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12
                  v-if="this.option==='Branch'||this.option==='Regional'||this.option==='Territory'">
            <v-select
              v-if="!branch_loading"
              v-model="branch_id"
              class="mx-2"
              dense
              outlined
              label="Branch Data"
              :items="branch_items"
              item-value="id"
              item-text="branch_code"
              :rules="rules.combobox_rule"
              @change="view_report"
            ></v-select>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-flex>
        </v-layout>
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"

          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert_message}}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>
      </v-form>
      <div v-if="view_by==='Figures'">
        <v-data-table dense
                      v-if="this.option==='Summary'||this.option==='Summary Particulars'"
                      class="mt-3"
                      :headers="headers"
                      :items="data_items"
                      disable-pagination
                      hide-default-footer
        >
          <template v-slot:top v-if="data_items.length>0">
            <h2 v-if="!saving_data" class="mx-2">PRINT
              <v-icon
                class="mr-2"
                color="success"
                @click="print_data"
              >
                {{icons.mdiPrinter}}
              </v-icon>
            </h2>

            <v-progress-circular
              :size=50
              :width="5"
              color="success"
              indeterminate
              v-else
            ></v-progress-circular>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.particulars }}
              </td>
              <td>
                {{item.cash_expense===0?'': formatPrice(item.cash_expense) }}
              </td>
              <td>
                {{item.unliquidated===0?'': formatPrice(item.unliquidated) }}
              </td>
              <td>
                {{item.pdc_expense===0?'': formatPrice(item.pdc_expense) }}
              </td>
              <td>
                {{item.auto_deb_expense===0?'': formatPrice(item.auto_deb_expense) }}
              </td>
              <td>
                {{item.total_expense===0?'': formatPrice(item.total_expense) }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-data-table dense
                      v-if="(this.option==='Particulars'||this.option==='Branch'||this.option==='Regional'||this.option==='Territory')&&(this.branch_id!=-1)"
                      class="mt-3"
                      :headers="this.is_liquidation?headers5:headers2"
                      :items="data_items"
                      disable-pagination
                      hide-default-footer
        >
          <template v-slot:top v-if="data_items.length>0">
            <h2 v-if="!saving_data" class="mx-2">PRINT
              <v-icon
                class="mr-2"
                color="success"
                @click="print_data"
              >
                {{icons.mdiPrinter}}
              </v-icon>
            </h2>

            <v-progress-circular
              :size=50
              :width="5"
              color="success"
              indeterminate
              v-else
            ></v-progress-circular>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.voucher_no }}
              </td>
              <td>
                {{ item.branch }}
              </td>
              <td>
                {{ item.date }}
              </td>
              <td>
                {{ item.description }}
              </td>
              <td>
                {{ item.credit_to }}
              </td>
              <td>
                {{ item.payment_for }}
              </td>
              <td>
                {{item.cash===0?'': formatPrice(item.cash) }}
              </td>
              <td>
                {{item.unliquidated===0?'': formatPrice(item.unliquidated) }}
              </td>
              <td>
                {{item.cheque===0?'': formatPrice(item.cheque) }}
              </td>
              <td>
                {{item.auto_deb===0?'': formatPrice(item.auto_deb) }}
              </td>
              <td v-if="is_liquidation">
                <v-icon
                  v-if="item.cash>0 && item.id>0 && item.monthly_liquidation.edit_history.length===0"
                  class="mr-2" color="info"
                  @click="edit_liquidation(item.monthly_liquidation,false)">
                  {{ icons.mdiPencil }}
                </v-icon>
                <v-icon
                  v-if="item.cash>0 && item.id>0 && item.monthly_liquidation.edit_history.length>0"
                  class="mr-2" color="success"
                  @click="edit_liquidation(item.monthly_liquidation,true)">
                  {{ icons.mdiEyeCircleOutline }}
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-data-table dense
                      v-if="(this.option==='Particulars'||this.option==='Branch'||this.option==='Regional'||this.option==='Territory')&&(this.branch_id===-1)"
                      class="mt-3"
                      :headers="headers4"
                      :items="data_items"
                      disable-pagination
                      hide-default-footer
        >
          <template v-slot:top v-if="data_items.length>0">
            <h2 v-if="!saving_data" class="mx-2">PRINT
              <v-icon
                class="mr-2"
                color="success"
                @click="print_data"
              >
                {{icons.mdiPrinter}}
              </v-icon>
            </h2>

            <v-progress-circular
              :size=50
              :width="5"
              color="success"
              indeterminate
              v-else
            ></v-progress-circular>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.branch }}
              </td>
              <td>
                {{item.cash===0?'': formatPrice(item.cash) }}
              </td>
              <td>
                {{item.cheque===0?'': formatPrice(item.cheque) }}
              </td>
              <td>
                {{item.auto_deb===0?'': formatPrice(item.auto_deb) }}
              </td>
              <td>
                {{item.unliquidated===0?'': formatPrice(item.unliquidated) }}
              </td>
              <td>
                {{(parseFloat(item.cash)+parseFloat(item.cheque)+parseFloat(item.auto_deb)+parseFloat(item.unliquidated))===0?'':
                formatPrice(parseFloat(item.cash)+parseFloat(item.cheque)+parseFloat(item.auto_deb)+parseFloat(item.unliquidated))
                }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-data-table dense
                      v-if="this.option==='UnLiquidated'"
                      class="mt-3"
                      :headers="headers3"
                      :items="data_items"
                      disable-pagination
                      hide-default-footer
        >
          <template v-slot:top v-if="data_items.length>0">
            <h2 v-if="!saving_data" class="mx-2">PRINT
              <v-icon
                class="mr-2"
                color="success"
                @click="print_data"
              >
                {{icons.mdiPrinter}}
              </v-icon>
            </h2>

            <v-progress-circular
              :size=50
              :width="5"
              color="success"
              indeterminate
              v-else
            ></v-progress-circular>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.voucher_no }}
              </td>
              <td>
                {{ item.branch }}
              </td>
              <td>
                {{ item.date }}
              </td>
              <td>
                {{ item.particulars }}
              </td>
              <td>
                {{ item.description }}
              </td>
              <td>
                {{ item.credit_to }}
              </td>
              <td>
                {{item.cash===0?'': formatPrice(item.cash) }}
              </td>
              <td>
                {{item.unliquidated===0?'': formatPrice(item.unliquidated) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <div v-else>
        <DonutChart
          :key="this.$vuetify.theme.dark || this.key"
          :dataPoints="this.dataPoints"
          :report_title="'MONTHLY EXPENDITURE REPORT'"
          :report_details="this.report_details"
        ></DonutChart>
      </div>
    </v-card>
    <v-dialog v-model="EditInfo" persistent max-width="50%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">EDIT LIQUIDATIONS</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-form ref="form" class="multi-col-validation">
            <v-row dense>
              <v-col md="12"
                     cols="12">
                <v-select
                  v-if="!is_for_approved"
                  v-model="category_id2"
                  class="mx-2"
                  dense
                  outlined
                  label="Category"
                  :items="category_items2"
                  item-value="id"
                  item-text="category"
                  :rules="rules.combobox_rule"
                  @change="selected_category2"
                ></v-select>
                <v-text-field
                  v-else
                  class="mx-2"
                  v-model="category_f"
                  dense
                  label="Category"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col md="12"
                     cols="12">
                <v-select
                  outlined
                  v-if="!is_forbidden &&!is_for_approved"
                  class="mx-2"
                  v-model="selection2"
                  dense
                  label="Selection"
                  :items="['Branch','Regional','Territory']"
                  :rules="rules.combobox_rule"
                  @change="selected_category2"
                ></v-select>
              </v-col>
              <v-col md="12"
                     cols="12">
                <v-select
                  v-if="!is_for_approved"
                  class="mx-2"
                  v-model="branch_id2"
                  dense
                  label="Branch"
                  :items="branch_items2"
                  item-value="id"
                  item-text="branch_code"
                  outlined
                  :rules="rules.combobox_rule"
                ></v-select>
                <v-text-field
                  v-else
                  class="mx-2"
                  v-model="branch_f"
                  dense
                  label="Branch"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col md="12"
                     cols="12">
                <v-select
                  v-if="!is_for_approved"
                  class="mx-2"
                  v-model="particulars_of"
                  dense
                  label="Particulars Of"
                  :items="particulars_of_items"
                  item-text="particulars"
                  item-value="id"
                  outlined
                  :rules="rules.combobox_rule"
                  @change="selected_particulars"
                ></v-select>
                <v-text-field
                  v-else
                  class="mx-2"
                  v-model="particulars_f"
                  dense
                  label="Particulars Of"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col md="12"
                     cols="12" v-if="!is_for_approved">
                <customize-components
                  v-if="has_description"
                  :key="this.key"
                  :description="this.description"
                  :particulars_id="this.particulars_of"
                  :particulars_items="this.particulars_of_items"
                  :branch_id="this.branch_id2"
                  :particulars_description_items="this.particulars_description_items"
                  v-on:on_close_description_dialog="on_close_description_dialog"
                ></customize-components>
                <v-text-field
                  v-else
                  class="mx-2"
                  v-model="description"
                  dense
                  label="Description"
                  outlined
                  :rules="rules.default_max_500_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col md="12"
                     cols="12" v-if="is_for_approved">
                <v-text-field
                  class="mx-2"
                  v-model="description"
                  dense
                  label="Description"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col md="12"
                     cols="12">
                <v-text-field
                  v-if="!is_for_approved"
                  class="mx-2"
                  v-model="payment_for"
                  dense
                  label="Payment For"
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
                <v-text-field
                  v-else
                  class="mx-2"
                  v-model="payment_for"
                  dense
                  label="Payment For"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col md="12"
                     cols="12">
                <v-text-field
                  v-if="!is_for_approved"
                  class="mx-2"
                  v-model="credit_to"
                  dense
                  label="Credit To"
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
                <v-text-field
                  v-else
                  class="mx-2"
                  v-model="credit_to"
                  dense
                  label="Credit To"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col md="6"
                     cols="12">
                <v-text-field
                  v-if="!is_for_approved"
                  class="mx-2"
                  v-model="date"
                  dense
                  label="Date"
                  outlined
                  type="date"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
                <v-text-field
                  v-else
                  class="mx-2"
                  v-model="date"
                  dense
                  label="Date"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col md="6"
                     cols="12">
                <v-text-field
                  v-if="!is_for_approved"
                  class="mx-2"
                  v-model="amount"
                  dense
                  label="Amount"
                  outlined
                  min="0"
                  type="number"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
                <v-text-field
                  v-else
                  class="mx-2"
                  v-model="amount"
                  dense
                  label="Amount"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-checkbox
                v-if="!is_for_approved"
                v-model="is_no_or"
                label="Is No OR #"
                dense
                outlined
              ></v-checkbox>
              <v-col md="10"
                     cols="12">
                <v-text-field
                  v-if="!is_no_or && !is_for_approved"
                  class="mx-2"
                  v-model="or_no"
                  dense
                  label="OR #"
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
                <v-text-field
                  v-else
                  class="mx-2"
                  v-model="or_no"
                  dense
                  label="OR #"
                  outlined
                  readonly
                ></v-text-field>
                <v-text-field
                  v-if="is_no_or"
                  class="mx-2"
                  v-model="specify"
                  dense
                  label="Specify"
                  outlined
                  :rules="rules.default_max_255_character_and_no_empty_rule"
                ></v-text-field>
                <v-text-field
                  v-if="is_no_or && is_for_approved"
                  class="mx-2"
                  v-model="specify"
                  dense
                  label="Specify"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"

                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12">
                <v-card-actions>
                  <v-btn
                    color="primary"
                    @click="save_liquidation"
                    v-if="!is_for_approved&&!saving_data"
                  >
                    Update changes
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="change_status_edit(true)"
                    v-if="is_for_approved && !saving_data && (employee_id===1||employee_id===2||employee_id===430||employee_id===429)"
                  >
                    Approved Changes
                  </v-btn>
                  <v-btn
                    color="warning"
                    @click="change_status_edit(false)"
                    v-if="is_for_approved && !saving_data && (employee_id===1||employee_id===2||employee_id===430||employee_id===429)"
                  >
                    DisApproved Changes
                  </v-btn>
                  <v-progress-circular
                    :size=50
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="saving_data"
                  ></v-progress-circular>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-btn color="error" @click="EditInfo = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiCheck, mdiClose
    , mdiPrinter
    , mdiPencil
    , mdiEyeCircleOutline
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import DonutChart from '@/views/pages/charts/DonutChart'
  import customizeComponents
    from '@/views/file_interface/create/request/request_components/CustomizeRequest'

  const initialState = () => {
    return {
      particulars_of_items: [],
      is_forbidden: false,
      is_for_approved: false,
      has_description: false,
      selection2: '',
      particulars_of: '',
      particulars_selected: '',
      description: '',
      payment_for: '',
      credit_to: '',
      date: '',
      amount: '',
      specify: '',
      or_no: '',
      current_page: '',
      is_no_or: false,
      saving_data: false,
      is_liquidation: false,
      EditInfo: false,
      alert: false,
      branch_loading: false,
      option_items: [],
      particulars_description_items: [],
      category_items: [],
      category_items2: [],
      month_of_items: [],
      particulars_items: [],
      branch_items2: [],
      branch_items: [],
      data_items: [],
      dataPoints: [],
      selected_liquidation: {},
      category_id: '',
      category_id2: '',
      report_details: '',
      branch_id2: '',
      branch_id: '',
      view_by: '',
      option: '',
      particulars_id: '',
      alert_message: '',
      selection: '',
      month_of_id: '',
      category_f: '',
      branch_f: '',
      particulars_f: '',
      total_amount: '0.00',
      key: 0,
      headers: [
        {text: 'Particulars', value: 'date_of_deposit', sortable: false},
        {text: 'Cash Expense', value: 'last_name', sortable: false},
        {text: 'UnLiquidated', value: 'last_name', sortable: false},
        {text: 'PDC Expense', value: 'last_name', sortable: false},
        {text: 'Auto Debit Expense', value: 'last_name', sortable: false},
        {text: 'Total Expense', value: 'last_name', sortable: false},
      ],
      headers2: [
        {text: 'Voucher #', value: 'date_of_deposit', sortable: false},
        {text: 'Branch', value: 'last_name', sortable: false},
        {text: 'Date', value: 'last_name', sortable: false},
        {text: 'Description', value: 'last_name', sortable: false},
        {text: 'Credited To', value: 'last_name', sortable: false},
        {text: 'Cash', value: 'last_name', sortable: false},
        {text: 'UnLiquidated', value: 'last_name', sortable: false},
        {text: 'PDC', value: 'last_name', sortable: false},
        {text: 'Auto Debit', value: 'last_name', sortable: false},
      ],
      headers5: [
        {text: 'Voucher #', value: 'date_of_deposit', sortable: false},
        {text: 'Branch', value: 'last_name', sortable: false},
        {text: 'Date', value: 'last_name', sortable: false},
        {text: 'Description', value: 'last_name', sortable: false},
        {text: 'Credited To', value: 'last_name', sortable: false},
        {text: 'Payment For', value: 'last_name', sortable: false},
        {text: 'Cash', value: 'last_name', sortable: false},
        {text: 'UnLiquidated', value: 'last_name', sortable: false},
        {text: 'PDC', value: 'last_name', sortable: false},
        {text: 'Auto Debit', value: 'last_name', sortable: false},
        {text: 'Edit/Approved', value: 'last_name', sortable: false},
      ],
      headers4: [
        {text: 'Branch', value: 'last_name', sortable: false},
        {text: 'Cash', value: 'last_name', sortable: false},
        {text: 'PDC', value: 'last_name', sortable: false},
        {text: 'Auto Debit', value: 'last_name', sortable: false},
        {text: 'Unliquidated', value: 'last_name', sortable: false},
        {text: 'Total', value: 'last_name', sortable: false},
      ],
      headers3: [
        {text: 'Voucher #', value: 'date_of_deposit', sortable: false},
        {text: 'Branch', value: 'last_name', sortable: false},
        {text: 'Date', value: 'last_name', sortable: false},
        {text: 'Particulars', value: 'last_name', sortable: false},
        {text: 'Description', value: 'last_name', sortable: false},
        {text: 'Credited To', value: 'last_name', sortable: false},
        {text: 'Cash', value: 'last_name', sortable: false},
        {text: 'Unliquidated', value: 'last_name', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
      DonutChart,
      customizeComponents,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiPrinter,
          mdiPencil,
          mdiEyeCircleOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      switch (this.$router.currentRoute.name) {
        case "liquidations-maintenance":
          this.current_page = 'LIQUIDATION MAINTENANCE'
          this.is_liquidation = true
          break;
        default:
          this.current_page = 'MONTHLY EXPENDITURE REPORT'
          this.is_liquidation = false
          break;
      }
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
      ...mapGetters('authentication', ['address', 'contact', 'name', 'department', 'position', 'employee_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('request_particulars', ['request_particulars_data']),
      ...mapActions('branch_information', ['branch_data']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee']),
      ...mapActions('monthly_liquidation', ['list_of_category_have_epxense', 'monthly_expenditure', 'particulars_available_in_expenditure', 'branch_available_in_expenditure', 'list_of_request_unliquidated', 'update_liquidation', 'change_status_pending_edit']),
      ...mapActions('transaction_months', ['list_of_transaction_month']),
      async edit_liquidation(value, is_for_approved) {
        this.is_for_approved = is_for_approved
        await this.initialize_salaries_employee()
          .then(response => {
            this.category_items2 = response.data[0].category
          })
          .catch(error => {
            console.log(error)
          })
        if (is_for_approved) {
          this.has_description = false
          this.description = value.edit_history[0].description
          this.payment_for = value.edit_history[0].payment_for === null ? request_data.payment_for : value.edit_history[0].payment_for
          this.credit_to = value.edit_history[0].credit_to
          this.date = value.edit_history[0].date
          this.or_no = value.edit_history[0].or_no
          this.specify = value.edit_history[0].specify
          this.is_no_or = value.edit_history[0].or_no === null
          this.amount = value.edit_history[0].amount
          this.selected_liquidation = value.edit_history[0]
          this.category_id2 = value.edit_history[0].category_id
          this.EditInfo = true
        } else {
          this.description = value.description
          this.payment_for = value.payment_for === null ? value.request_data.payment_for : value.payment_for
          this.credit_to = value.credit_to
          this.date = value.date
          this.or_no = value.or_no
          this.specify = value.specify
          this.is_no_or = value.or_no === null
          this.amount = value.amount
          this.selected_liquidation = value
          this.category_id2 = value.category_id
          this.EditInfo = true

        }

        await this.selected_category2()
      },
      async selected_category2() {
        this.is_forbidden = true
        if (this.category_id2 === 4 || this.category_id2 === 3) {
          this.is_forbidden = false
        }
        var index = this.category_items2
          .map(function (x) {
            return x.id
          })
          .indexOf(this.category_id2)
        if (index != -1) {
          this.category_f = this.category_items2[index].category
          await this.branch_data({
            category_id: this.category_id2,
            is_region: this.selection2 === 'Regional' ? 1 : 0,
            is_territory: this.selection2 === 'Territory' ? 1 : 0,
          })
            .then(response => {
              this.branch_items2 = response.data[0].active
            })
            .catch(error => {
              console.log(error)
            })
          this.branch_id2 = this.selected_liquidation.branch_id
          var index2 = this.branch_items2
            .map(function (x) {
              return x.id
            })
            .indexOf(this.branch_id2)
          if (index2 != -1) {
            this.branch_f = this.branch_items2[index2].branch_code
          }
          await this.request_particulars_data({
            category_id: this.category_id2,
          })
            .then(response => {
              this.particulars_of_items = response.data[0].active
            })
            .catch(error => {
              console.log(error)
            })
          this.particulars_of = this.selected_liquidation.particulars_id
          this.selected_particulars()
          this.description = this.selected_liquidation.description
        }
      },
      selected_particulars() {
        this.has_description = false
        this.description = ''
        this.key++
        if (this.particulars_of_items.length > 0) {
          var index = this.particulars_of_items.map(function (x) {
            return x.id;
          }).indexOf(this.particulars_of)
          if (index != -1) {
            this.particulars_f = this.particulars_of_items[index].particulars
            this.particulars_selected = this.particulars_of_items[index].particulars
            this.has_description = this.particulars_of_items[index].has_description === 1
            this.particulars_description_items = this.particulars_of_items[index].data
            if (this.has_description) {
              this.description = 'Please Click Me!!!'
            }
          }
        }
      },
      on_close_description_dialog(value) {
        this.description = value
      },
      async save_liquidation() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          if (this.selectedParticularsID === -1) {
            this.alert = true
            this.alert_message = 'Please Select Particular First'
            this.saving_data = false
          } else if (this.description === 'Please Click Me!!!') {
            this.alert = true
            this.alert_message = 'Please Select Particular First'
            this.saving_data = false
          } else {
            const data = new FormData()
            data.append('category_id', this.category_id2);
            data.append('date', this.date);
            data.append('credit_to', this.credit_to.toUpperCase());
            data.append('payment_for', this.payment_for.toUpperCase());
            var final_particlars = this.particulars_selected
            switch (this.particulars_selected) {
              case "Branch Expense":
              case "Regional Expense":
              case "Benefits":
                final_particlars = this.description.split(' # ')[0]
                break;
              case "HO Budget":
              case "Revolving Fund":
              case "Chapel Fund":
              case "Poultry Fund":
                final_particlars = this.particulars_of
                break;
            }
            data.append('id', this.selected_liquidation.id);
            data.append('particulars', final_particlars);
            data.append('amount', this.amount);
            data.append('description', this.description);
            data.append('branch_id', this.branch_id2);
            data.append('is_no_or', this.is_no_or);
            data.append('or_no', this.or_no);
            data.append('specify', this.specify);
            data.append('employee_id', this.employee_id);
            await this.update_liquidation(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: response.status === 200 ? 'success' : 'error',
                  text: response.data,
                })
                this.EditInfo = false
                this.saving_data = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving_data = false
              })

            await this.view_report()
            this.key++
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      async change_status_edit(value) {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          if (this.selectedParticularsID === -1) {
            this.alert = true
            this.alert_message = 'Please Select Particular First'
            this.saving_data = false
          } else if (this.description === 'Please Click Me!!!') {
            this.alert = true
            this.alert_message = 'Please Select Particular First'
            this.saving_data = false
          } else {
            const data = new FormData()
            data.append('id', this.selected_liquidation.id);
            data.append('is_approved', value ? 1 : 0);
            data.append('employee_id', this.employee_id);
            await this.change_status_pending_edit(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: response.status === 200 ? 'success' : 'error',
                  text: response.data,
                })
                this.EditInfo = false
                this.saving_data = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving_data = false
              })

            await this.view_report()
            this.key++
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      initialize_data() {
        this.list_of_transaction_month()
          .then(response => {
            this.month_of_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async selected_selection() {
        this.data_items = []
        this.category_items = []
        this.particulars_items = []
        this.category_id = ''
        this.particulars_id = ''
        this.branch_id = ''
        this.option = ''
        if (this.selection != '') {
          await this.list_of_category_have_epxense({
            is_current: this.selection === 'Current',
            month_of_id: this.month_of_id
          })
            .then(response => {
              this.category_items = response.data
              this.view_by = 'Figures'
              if (!this.is_liquidation) {
                this.category_items.splice(0, 0, {
                  id: -1,
                  category: 'All'
                })
              }
            })
            .catch(error => {
              console.log(error)
            })
          await this.view_report()
        }
      },
      async selected_option() {
        this.data_items = []
        this.particulars_id = ''
        this.branch_id = ''
        this.branch_loading = true
        this.branch_items = []
        this.particulars_items = []
        if (this.option === 'Particulars') {
          await this.particulars_available_in_expenditure({
            month_of_id: this.month_of_id,
            month_of: this.month_of_items[this.month_of_items.map(function (x) {
              return x.id;
            }).indexOf(this.month_of_id)].month_of,
            category_id: this.category_id,
            is_current: this.selection === 'Current' ? 1 : 0,
          })
            .then(response => {
              this.particulars_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
        } else if (this.option === 'Branch') {
          await this.branch_available_in_expenditure({
            month_of_id: this.month_of_id,
            month_of: this.month_of_items[this.month_of_items.map(function (x) {
              return x.id;
            }).indexOf(this.month_of_id)].month_of,
            category_id: this.category_id,
            is_branch: this.option === 'Branch' ? 1 : 0,
            is_region: 0,
            is_territory: 0,
          })
            .then(response => {
              this.branch_items = response.data
              this.branch_items.splice(0, 0, {
                id: -1,
                branch_code: 'All'
              })
            })
            .catch(error => {
              console.log(error)
            })
        } else if (this.option === 'Regional') {
          await this.branch_available_in_expenditure({
            month_of_id: this.month_of_id,
            month_of: this.month_of_items[this.month_of_items.map(function (x) {
              return x.id;
            }).indexOf(this.month_of_id)].month_of,
            category_id: this.category_id,
            is_region: this.option === 'Regional' ? 1 : 0,
            is_branch: 0,
            is_territory: 0,
          })
            .then(response => {
              this.branch_items = response.data
              this.branch_items.splice(0, 0, {
                id: -1,
                branch_code: 'All'
              })
            })
            .catch(error => {
              console.log(error)
            })
        } else if (this.option === 'Territory') {
          await this.branch_available_in_expenditure({
            month_of_id: this.month_of_id,
            month_of: this.month_of_items[this.month_of_items.map(function (x) {
              return x.id;
            }).indexOf(this.month_of_id)].month_of,
            category_id: this.category_id,
            is_territory: this.option === 'Territory' ? 1 : 0,
            is_region: 0,
            is_branch: 0,
          })
            .then(response => {
              this.branch_items = response.data
              this.branch_items.splice(0, 0, {
                id: -1,
                branch_code: 'All'
              })
            })
            .catch(error => {
              console.log(error)
            })
        } else if (this.option === 'Summary Particulars') {
          await this.view_report()
        } else if (this.option === 'UnLiquidated') {
          await this.view_report1()
        } else {
          await this.view_report()
        }
        this.branch_loading = false
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      async view_report() {
        this.data_items = []
        this.saving_data = true
        this.alert = true
        this.alert_message = 'PLEASE WAIT...'
        this.dataPoints = []
        if (this.$refs.form.validate()) {
          await this.monthly_expenditure({
            month_of_id: this.month_of_id,
            month_of: this.month_of_items[this.month_of_items.map(function (x) {
              return x.id;
            }).indexOf(this.month_of_id)].month_of,
            category_id: this.category_id,
            particulars_id: this.particulars_id,
            branch_id: this.branch_id,
            is_summary_particulars: this.option === 'Summary Particulars' ? 1 : 0,
            is_current: this.selection === 'Current' ? 1 : 0,
            is_branch: this.option === 'Branch' ? 1 : 0,
            is_region: this.option === 'Regional' ? 1 : 0,
            is_territory: this.option === 'Territory' ? 1 : 0,
          })
            .then(response => {
              this.data_items = response.data
              this.saving_data = false
              this.alert = false
              this.alert_message = ''
            })
            .catch(error => {
              console.log(error)
            });
          var dd = []
          if (this.view_by != 'Figures') {
            this.report_details = 'Month of: ' + (this.month_of_items[this.month_of_items.map(function (x) {
              return x.id;
            }).indexOf(this.month_of_id)].month_of) + '         ' + (this.option === 'Summary' || this.option === 'UnLiquidated' ? this.option :
              (this.option === 'Particulars' ? this.option + ' ( ' + this.particulars_items[this.particulars_items.map(function (x) {
                return x.id;
              }).indexOf(this.particulars_id)].particulars + ' )' :
                (this.option === 'Branch' || this.option === 'Regional' || this.option === 'Territory' ? this.option + ' ( ' + this.branch_items[this.branch_items.map(function (x) {
                  return x.id;
                }).indexOf(this.branch_id)].branch_code + ' )' : '')))
            await this.data_items.forEach(function (item) {
              var randomColor = Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
              if (item.id >= 0) {
                dd.push({
                  label: item.particulars,
                  y: item.total_expense,
                  color: "#" + randomColor
                })
              }
            })
            this.dataPoints = dd
            this.key++
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      view_report1() {
        this.data_items = []
        this.saving_data = true
        this.alert = true
        this.alert_message = 'PLEASE WAIT...'
        if (this.$refs.form.validate()) {
          this.list_of_request_unliquidated({
            month_of_id: this.month_of_id,
            category_id: this.category_id,
          })
            .then(response => {
              this.data_items = response.data
              this.saving_data = false
              this.alert = false
              this.alert_message = ''
            })
            .catch(error => {
              console.log(error)
            });
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      async selected_category() {
        this.data_items = []
        this.particulars_id = ''
        this.branch_id = ''
        this.option = ''
        var index = this.category_items.map(function (x) {
          return x.id;
        }).indexOf(this.category_id)
        if (index != -1) {
          if (this.view_by === 'Figures') {
            this.dataPoints = []
            this.key++
            this.option_items = ['Summary', 'Particulars', 'Branch', 'UnLiquidated']
            if (this.category_id === 4 || this.category_id === 3) {
              this.option_items = ['Summary', 'Particulars', 'Branch', 'Regional', 'Territory', 'UnLiquidated']
            }
            if (this.category_id === -1) {
              this.option_items = ['Summary Particulars', 'Summary']
            }
            if (this.is_liquidation) {
              this.option_items = ['Particulars']
            }
          } else {
            this.option_items = ['Summary']
          }
        }
      },
      print_data() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var imgData = this.company_logo
          var payments_array = []
          var widths = []
          var payment = 0
          var info_array = []

          if (this.option === 'Summary') {
            widths = [200, 70, 70, 70, 70, 70]
            payments_array.push([
              {text: 'Particulars', alignment: 'left', style: 'main_info',},
              {text: 'Cash Expense', alignment: 'left', style: 'main_info'},
              {text: 'PDC Expense', alignment: 'left', style: 'main_info'},
              {text: 'Auto Debit Expense', alignment: 'left', style: 'main_info'},
              {text: 'Total Expense', alignment: 'left', style: 'main_info'},
            ])
            this.data_items.forEach(function (item) {
              payments_array.push([
                {
                  text: item.particulars,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.cash_expense === 0 ? '' : (item.cash_expense / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.pdc_expense === 0 ? '' : (item.pdc_expense / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.auto_deb_expense === 0 ? '' : (item.auto_deb_expense / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.total_expense === 0 ? '' : (item.total_expense / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2', color: 'red', bold: true,
                },
              ])
            })
          } else if ((this.option === 'Particulars' || this.option === 'Branch' || this.option === 'Regional' || this.option === 'Territory') && (this.branch_id != -1)) {
            widths = [30, 50, 40, 150, 100, 40, 40, 40]
            payments_array.push([
              {text: 'Vouc #', alignment: 'left', style: 'main_info',},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Date', alignment: 'left', style: 'main_info'},
              {text: 'Description', alignment: 'left', style: 'main_info'},
              {text: 'Credited To', alignment: 'left', style: 'main_info'},
              {text: 'Cash', alignment: 'left', style: 'main_info'},
              {text: 'PDC', alignment: 'left', style: 'main_info'},
              {text: 'Auto Debit', alignment: 'left', style: 'main_info'},
            ])
            this.data_items.forEach(function (item) {
              payments_array.push([
                {
                  text: item.voucher_no,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.branch,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.date,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.description,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.credit_to,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.cash === 0 ? '' : (item.cash / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2', color: 'red', bold: 'true'
                },
                {
                  text: item.cheque === 0 ? '' : (item.cheque / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2', color: 'red', bold: 'true'
                },
                {
                  text: item.auto_deb === 0 ? '' : (item.auto_deb / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2', color: 'red', bold: 'true'
                },
              ])
            })
          } else if ((this.option === 'Particulars' || this.option === 'Branch' || this.option === 'Regional' || this.option === 'Territory') && (this.branch_id === -1)) {
            widths = [150, 60, 60, 60, 60, 80]
            payments_array.push([
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Cash', alignment: 'left', style: 'main_info'},
              {text: 'PDC', alignment: 'left', style: 'main_info'},
              {text: 'Auto Debit', alignment: 'left', style: 'main_info'},
              {text: 'Unliquidated', alignment: 'left', style: 'main_info'},
              {text: 'Total', alignment: 'left', style: 'main_info'},
            ])
            this.data_items.forEach(function (item) {
              payments_array.push([
                {
                  text: item.branch,
                  alignment: 'left',
                  style: 'tableExample2'
                },

                {
                  text: item.cash === 0 ? '' : (item.cash / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2', color: 'red', bold: 'true'
                },
                {
                  text: item.cheque === 0 ? '' : (item.cheque / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2', color: 'red', bold: 'true'
                },
                {
                  text: item.auto_deb === 0 ? '' : (item.auto_deb / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2', color: 'red', bold: 'true'
                },
                {
                  text: item.unliquidated === 0 ? '' : (item.unliquidated / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2', color: 'red', bold: 'true'
                },
                {
                  text: (parseFloat(item.cash) + parseFloat(item.cheque) + parseFloat(item.auto_deb) + parseFloat(item.unliquidated)) === 0 ? ''
                    : ((parseFloat(item.cash) + parseFloat(item.cheque) + parseFloat(item.auto_deb) + parseFloat(item.unliquidated)) / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2', color: 'red', bold: 'true'
                },
              ])
            })
          } else if (this.option === 'UnLiquidated') {
            widths = [30, 50, 40, 60, 180, 100, 40,]
            payments_array.push([
              {text: 'Vouc #', alignment: 'left', style: 'main_info',},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Date', alignment: 'left', style: 'main_info'},
              {text: 'Particulars', alignment: 'left', style: 'main_info'},
              {text: 'Description', alignment: 'left', style: 'main_info'},
              {text: 'Credited To', alignment: 'left', style: 'main_info'},
              {text: 'Cash', alignment: 'left', style: 'main_info'},
            ])
            this.data_items.forEach(function (item) {
              payments_array.push([
                {
                  text: item.voucher_no,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.branch,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.date,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.particulars,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.description,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.credit_to,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.cash === 0 ? '' : (item.cash / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2', color: 'red', bold: 'true'
                },
              ])
            })
          }

          info_array = [
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    {
                      text: this.option === 'Summary' || this.option === 'UnLiquidated' ? this.option :
                        (this.option === 'Particulars' ? this.option + ' ( ' + this.particulars_items[this.particulars_items.map(function (x) {
                          return x.id;
                        }).indexOf(this.particulars_id)].particulars + ' )' :
                          (this.option === 'Branch' || this.option === 'Regional' || this.option === 'Territory' ? this.option + ' ( ' + this.branch_items[this.branch_items.map(function (x) {
                            return x.id;
                          }).indexOf(this.branch_id)].branch_code + ' )' : '')),
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Month of: ',
                    {
                      text: this.month_of_items[this.month_of_items.map(function (x) {
                        return x.id;
                      }).indexOf(this.month_of_id)].month_of,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
          ]

          //call pdfmake
          var pdfMake = require('pdfmake/build/pdfmake.js')
          if (pdfMake.vfs == undefined) {
            var pdfFonts = require('pdfmake/build/vfs_fonts.js')
            pdfMake.vfs = pdfFonts.pdfMake.vfs
          }
          var docDefinition = {
            pageSize: {
              width: 612,
              height: 936,
            },
            pageOrientation: 'portrait',
            content: [
              {
                columns: [
                  {image: imgData, width: 54, height: 54, style: 'logo'},
                  {
                    stack: [
                      'LABOTTEGA',
                      {text: this.address, style: 'subheader'},
                      {
                        text: this.contact,
                        style: 'subheader',
                      },
                      {
                        text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                        style: 'printed_label',
                      },
                    ],
                    style: 'header',
                  },
                ],
              },
              '_______________________________________________________________________________________________',
              {
                stack: [
                  {
                    columns: [
                      {
                        text: 'MONTHLY EXPENDITURE',
                        style: {
                          fontSize: 16,
                          alignment: 'center',
                          bold: true,
                          color: 'blue',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                stack: info_array,
              },
              {
                columns: [{text: ' '}],
              },
              {
                table: {
                  widths: widths,
                  body: payments_array,
                  style: {fontSize: 1},
                },
              },
              {
                stack: [
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [
                      {
                        text: 'PROCESSED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: 'DEPARTMENT RECEIVER',
                        style: 'main_data_2',
                      },
                      {
                        text: 'RECEIVED BY',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.name,
                        style: 'main_data_2',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.position,
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'VERIFIED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_________________________________________',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: ' ',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'BOARD OF DIRECTOR',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                ],
              },
            ],
            footer: {
              // columns: [
              //     {
              //         text: 'Generated with LABOTTEGA System',
              //         alignment: 'center',
              //         style: 'tableExample'
              //     }
              // ]
            },
            styles: {
              tableExample: {
                fontSize: 9,
              },
              tableExample2: {
                fontSize: 7,
              },
              header: {
                fontSize: 21,
                bold: true,
                alignment: 'left',
                margin: [6, 6, 0, 5], //[left, top, right, bottom]
              },
              subheader: {
                fontSize: 11,
              },
              logo: {
                alignment: 'center',
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              printed_label: {
                alignment: 'right',
                fontSize: 9,
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              main_data: {
                margin: [0, 2, 0, 2],
                fontSize: 11,
                bold: true,
                alignment: 'left',
              },
              main_data_2: {
                margin: [0, 2, 0, 2],
                fontSize: 8,
                bold: true,
                alignment: 'left',
              },
              main_info: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
                alignment: 'left',
              },
            },
          }
          pdfMake.createPdf(docDefinition).open()
          this.saving_data = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      }
    },
  }
</script>
